$white: #fff;
$boulder:#777;
$primary:#0072EF;
$danger:#FF3434;
$success:#01E65C;
$black-pearl:#051324;
$background:#FAFEFF;
$lilywhite:#E8FAFF;
$scieneblue:#006ADE;
$gold:#FFD102;
$malachite: #01E65C;
$primary: #f08f1f; 
$small: 600px;



.mainBanner
{
    width: 100%;
    height: calc(100vh - 110px);
    max-height: 1080px;
    // padding-top: #{unquote("min(calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320))), 200px)")};
    // padding-bottom: #{unquote("min(calc(20vh + (15 - 20)*((100vw - 320px)/(2560 - 320))), 200px)")};  
    min-height: 900px;  
    color: $white;
    background-size: cover;
    background-position: center center;
    @media only screen and (max-width: 768px)
    {
        background-position: center right;
    }
    .name
    {
        font-size: calc(34px + (48 - 34)*((100vw - 320px)/(2560 - 320)));
        font-weight: 700;
    }
    .description
    {
        font-size: calc(16px + (20 - 16)*((100vw - 320px)/(2560 - 320)));
        font-weight: 300;
        line-height: 1.5;
    }

    .searchForm
    {
        display: flex;
        flex-direction: row;
        background: $white;
        border-radius: 50px;
        align-content: center;
        box-shadow: 0px 10px 20px -5px rgba(5, 19, 36, 0.1);
        .input
        {
            border: 0px !important;
            box-shadow: none;
        }
    } 
}

.addBlocks{
    margin-bottom: -4px;
}


.listItemButton {
    position: relative;
    top: 10px !important;
  
    .iconSvg {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      opacity: 0;
      transform: translateX(0px);
    }
  
    &:hover .iconSvg {
      opacity: 0.7;
      transform: translateX(5px);
    }

    .iconSvg2 {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      opacity: 0;
      transform: translateX(0px);
    }
  
    &:hover .iconSvg2 {
      opacity: 1;
      transform: translateX(5px);
    }

    .iconSvg3 {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      opacity: 0;
      transform: translateX(0px);
      fill: #fff;
    }
  
    &:hover .iconSvg3 {
      opacity: 1;
      transform: translateX(5px);
    }
  }

  .listItemButtonBack {
    top: 10px !important;

    position: relative;
  
    .iconSvg {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      opacity: 0;
      transform: translateX(0px);
      transform: rotate(180deg);
    }
  
    &:hover .iconSvg {
      opacity: 0.7;
      transform: translateX(-5px);
    }

    .iconSvg2 {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      opacity: 0;
      transform: translateX(0px);
    }
  
    &:hover .iconSvg2 {
      opacity: 1;
      transform: translateX(-5px);
    }

    .iconSvg3 {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      opacity: 0;
      transform: translateX(0px);
      fill: #fff;
    }
  
    &:hover .iconSvg3 {
      opacity: 1;
      transform: translateX(-5px);
    }
  }


  .myButtonAnimation {
    color: black;
  
    .textAnimation {
      position: relative;
      display: inline-block;
  
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 1px;
        background-color: #000000;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
      }
    }
  
    &:hover .textAnimation::before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }

  .myButtonAnimationWhite {
    color: rgb(255, 255, 255) !important;
  
    .textAnimation {
      position: relative !important;
      display: inline-block !important;
  
      &::before {
        content: ''!important;
        position: absolute !important;
        bottom: 0!important;
        right: 0!important;
        width: 0!important;
        height: 1px !important;
        background-color: #ffffff !important;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1) !important;
        z-index: 1 !important;
      }
    }
  
    &:hover .textAnimation::before {
      left: 0 !important;
      right: auto !important;
      width: 100% !important;
    }
  }

  .container {
    perspective: 1000px;
  }
  
  .cube {
    position: relative;
    width: 200px;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    transition: transform 1s;
  }
  
  .face {
    position: absolute;
    width: 200px;
    height: 200px;
    background: lightgray;
    border: 1px solid black;
  }
  
  .face1 {
    transform: rotateY(0deg) translateZ(100px);
  }
  
  .face2 {
    transform: rotateY(90deg) translateZ(100px);
  }
  
  .cube.rotate {
    transform: rotateY(-90deg);
  }


  .drawerCloseButton{

    .drawerSvg{
        transition: scale 0.3s ease-in-out;
        scale: 1;
    }

    &:hover .drawerSvg{
        scale: 1.4;
    }
  }

  .shadowBox {
    box-shadow: 0px 10px 25px 2px rgba(0, 0, 0, .2);
  
  }

  .blackButton{
  width: 100% !important;
  height: 100% !important;

  .MuiButtonBase-root .MuiTypography-root{
    font-size: 14px !important;
    }
 
    svg{
      fill: #000;
      
    }

    &:hover svg{
      opacity: 1;
    }

    a{
      color: #000;
      text-decoration: none;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 600;
    }

    @media (max-width: $small) {
      .MuiButtonBase-root .MuiTypography-root{
        font-size: 16px !important;
        }
    }
  }

  .whiteButton{
    box-shadow: 0 0 0 0 rgb(0, 0, 0), 0 0 0 0 black;

    .MuiButtonBase-root .MuiTypography-root{
      font-size: 14px !important;
      }

 
    .MuiButton-root{
      color: #fff;
      background-color: #000;
      font-size: 15px;
      font-weight: 600;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
    }
    .MuiButton-root:hover{
      color: #fff;
      background-color: #000;
    }

    svg{
      fill: #fff;
      
    }

    &:hover svg{
      opacity: 1;
    }

    &:hover{
      box-shadow: 0 3px 0 0 black, 0 -3px 0 0 black;
      transition: all 0.2s ease-in-out;    
    }

    a{
      color: #fff;
      text-decoration: none;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 600;
    }

    @media (max-width: $small) {
      .MuiButtonBase-root .MuiTypography-root{
        font-size: 16px !important;
        }
    }
  }

  .loadingBtn{

    &:hover{
      box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.12), 0 -3px 0 0 rgba(0, 0, 0, 0.12);
      // transition: all 0.2s ease-in-out;    
    }


  }

  .disabled {

    &:hover {
      box-shadow: none;
    }
  }



  .breadcrumbBox {
    position: relative;
    padding: 1rem;
    background: white;
  
    &::before {
      content: "";
      position: absolute;
      top: -180px;
      left: 0;
      height: 180px;
      width: 100%;
      background: white;
    }
  }

  .footer {
    margin-bottom: -218px !important;
  }





 .standardText {

  

  .MuiFormLabel-root{
    color: #727272 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px !important;
    background: #fff !important;
    text-transform: none !important;

  
  
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;
    padding-right: 4px !important;
    z-index: 1 !important;
    background: #fff !important;

  }

  .MuiFormLabel-root {
    padding-right: 4px !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: black !important;
      
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 0px 11px !important; 
    border-radius: 0px 0px 0px 0px !important; 
  }

 }

 .standardText3 {

  

  .MuiFormLabel-root{
    color: #727272 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px !important;
    background: #fff !important;
    text-transform: none !important;

  
  
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;
    padding-right: 4px !important;
    z-index: 1 !important;
    background: #fff !important;

  }

  .MuiFormLabel-root {
    padding-right: 4px !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: black !important;
      
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 0px 11px !important; 
    border-radius: 0px 0px 0px 0px !important; 
  }

  // :hover{
  //   border: 1px solid black !important;
  // }

  .MuiInput-root.MuiInput-root:hover::before {
        border-bottom: 1px solid rgb(0, 0, 0) !important;

  }

 }


 .standardTextPayment {

  .MuiFormLabel-root{
    color: #727272 !important;
    // text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px !important;
    text-transform: none !important;
  
  
  }

  .MuiFormLabel-root.MuiInputLabel-shrink{
    color: $primary !important;
    font-weight: 800 !important;
    padding-right: 4px !important;
    z-index: 1 !important;
  }

  .MuiFormLabel-root.Mui-focused {
    // color: black !important;
    color: $primary !important;
    font-weight: 800 !important;
    padding-right: 4px !important;
    z-index: 1 !important;
  }

  .MuiFormLabel-root {
    padding-right: 4px !important;
  }



  .MuiOutlinedInput-notchedOutline {
    padding: 0px 11px !important; 
    border-radius: 0px 0px 0px 0px !important; 

    legend {
    
      width: 0 !important;
    }
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(5px, -26px) scale(0.75) !important;
    font-weight: 900 !important;
    font-size: 17px !important;
    color: $primary !important;
    text-decoration: none !important;
    text-transform: uppercase !important;

    &:hover{
      text-decoration: none !important;

 
    }

    
  }

 }

 .standardText2 {

  .MuiFormLabel-root{
    color: #727272 !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    margin-top: 0px !important;
  
  
  }

  .MuiFormLabel-root.Mui-focused {
    color: black !important;
    padding-right: 4px !important;
    z-index: 1 !important;
  }

  .MuiFormLabel-root {
    padding-right: 4px !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: black !important;
  }

  .MuiOutlinedInput-notchedOutline {
    padding: 0px 11px !important; 
    border-radius: 0px 0px 0px 0px !important; 
  }

 }

 .checkboxBlack{
  color: #000 !important;
  font-size: 30px !important;

  .MuiTypography-root{
    font-size: 26px !important;
  }

 }


  .button-grid {
    display: flex;
    flex-wrap: wrap;
    

  }
  

  
  .button-grid > *:nth-child(5n+1) {
    clear: both;

  }


  .optionsButton{
    height: 100% !important;

    .MuiButton-root {
      color: #000 !important;
      background: #fff !important;
      display: flex;
      justify-content: center;
      flex-direction: row;
      border: 1px solid #000;
      padding-left: 5px;
      padding-right: 5px;

      div div div img {
        border: 2px solid #000000 !important;
      }
     
      &:hover {
        color: #fff !important;
        background-color: #000 !important;
        // scale: 1.1;
        // border-radius: 3px;
        border: 1px solid #000;

        transition: all 0.2s ease-in-out;

        div div div img {
          border: 2px solid #fff !important;
        }
      }
    }

 
  }

  .optionsButtonSelected{


    .MuiButton-root {
      color: #ffffff !important;
      background: $primary !important;
      // scale: 1.1;
      display: flex;
      justify-content: center;
      flex-direction: row;
      border: 1px solid $primary;
      padding-left: 5px;
      padding-right: 5px;
      box-shadow: none;

      div div div img {
        border: 2px solid #ffffff !important;
      }
     
      &:hover {
        color: #fff !important;
        background-color: #000 !important;
        // scale: 1.1;
        // border-radius: 3px;
        border: 1px solid #000;

        transition: all 0.2s ease-in-out;
        box-shadow: none;


      }
    }

 
  }

  .myButtonAnimation2 {
    color: black;
    font-size: 22px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid $primary !important;
    // padding: 10px 30px !important;
    border-radius: 30px;


  
    a {
      position: relative; 
      z-index: 1;
      color: $primary;
      transition: color 0.6s cubic-bezier(0.25, 1, 0.5, 1); 
      text-decoration: none !important;
      font-size: 18px !important;
      
    }
  
    .textAnimation {
      position: relative;
      display: inline-block;
      border-radius: 25px;
      overflow: hidden;
      padding: 10px 30px !important;
      width: 100% !important;
  
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 65px;
        background-color: $primary;
        transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        z-index: 0;
        text-decoration: none !important;
      }
    }
  
    &:hover {
      a {
        color: #fff; 
      }

  
      .textAnimation::before {
        left: 0;
        right: auto;
        width: 100%;
      }
    }
  }

  .tabsSelector{
    color: #000 !important;

    .Mui-selected{
      color: #000 !important;
    }
  }

  .autocompleteField{
   



    .MuiInputBase-root{
      height: 100% !important;
    }

    .MuiFormControl-root{
      height: 100% !important;
    }

    .MuiOutlinedInput-root {
      position: relative;
    
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-bottom: 2px solid transparent;
      }
    
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background-color: $primary;
        transition: width 0.3s ease-in-out;
        transform: translateX(-50%);
      }
    
      &.Mui-focused:after {
        width: 100%;
      }
    }

    .MuiInputLabel-root{
      margin-top: 0px !important;
      color: gray !important;
      font-weight: 500 !important;
      text-transform: capitalize !important;
      text-decoration: none !important;


      &:hover{
        font-weight: 600 !important;
        text-decoration: none !important;


      }
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0px, -26px) scale(0.75) !important;
      font-weight: 900 !important;
      color: $primary !important;
      text-decoration: none !important;
      text-transform: uppercase !important;

      &:hover{
        text-decoration: none !important;

   
      }

      
    }
    

    .MuiOutlinedInput-notchedOutline{
      border: 0px !important;
      border-bottom: 1px solid gray !important;
      border-radius: 0px !important;
      font-weight: 600 !important;

      &:hover{
        border-bottom: 1px solid black !important;
   
      }
    }

    // .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    //   border-bottom: 1px solid black !important;

    // }

    // .css-1idcgzr-MuiInputBase-root-MuiOutlinedInput-root
  
  }

  .cartItem {

    .MuiButtonBase-root .MuiTypography-root{
      font-size: 14px !important;
      }
    
    .MuiListItemSecondaryAction-root{
    position: inherit !important;
    top: 0 !important;
    right: 0px !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    align-items: center !important;
    transform: none !important;

  }

  @media (max-width: $small) {
    .MuiButtonBase-root .MuiTypography-root{
      font-size: 16px !important;
      }
  }
  }

  .methodLabel{
    font-size: 14px !important;
    font-weight: 800 !important;

  }

  .cart-item{
    .MuiListItemButton-root{
      padding-right: 0px !important;
    }
  }

  .cart-item{
    .MuiListItemButton-root{
      padding-right: 0px !important;
    }
  }


  .contact-home {
    a {
      color: $primary !important;
      text-decoration: none !important;
    }
  }



  





  