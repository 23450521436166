
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300..800&display=swap');


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  font-size: 14px;
}

:root {
  --primary: #f08f1f; 
}

.mySelect .MuiInputLabel-outlined {
  transform: translate(12px, 8px) scale(1) !important;
}

.mySelect .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(0px, -6px) scale(0.75) !important;
}

.filterBox .mySelect .MuiInputLabel-outlined {
  transform: translate(12px, 8px) scale(1) !important;
}

.filterBox .mySelect .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -6px) scale(0.75) !important;
}


.logo
{
  background-image: url(assets/images/LOGO.svg);  
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  padding-bottom: 40px;
}

.toolbarLogo
{
  display: inline-block;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.test1
{
  background-color: red;
}

.test2
{ 
  background-color: green;
}

.test3
{
  background-color: yellow;
}

.test4
{
  background-color: blue;
}

.loginForm .MuiTextField-root {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.loginForm .MuiFormControl-root
{
  margin-bottom: 20px;
}

.loginForm .MuiFormHelperText-root
{
  position: absolute;
  bottom: 0px;
}

.loginForm .active
{
  color:green;
}

.loginForm .disabled
{
  color:red;
}

.headerAdmin
{
  display:flex !important;
  background-color: #fff !important;
}

.headerAdmin .logo
{
  display: inline-flex;
  max-width: 150px;
  margin-left: 0px;
}

.headerAdmin .userInfo
{
  color: #051324;
  margin-left: auto;
  font-size: 14px;
}

.MuiDrawer-paper
{
  background: #fff !important;
  color: #000 !important;
  font-size: 13px;
}

.MuiDrawer-paper .MuiDivider-root
{
  border-color: rgba(255, 255, 255, 0.5);
}

.addButton
{
  background: rgb(255, 0, 42);
  text-transform: uppercase;
  padding:5px 12px;
  text-decoration: none !important;
  color: #fff !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  line-height: 1;
  align-content: center;
  font-size: 12px;
  font-weight: 600 !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.addButton::before
{
  content: "+";
  margin-right: 5px;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
}

.breadcrumbs, .breadcrumbs li p
{
  font-size: 12px !important;
}

.navbarLeft ul
{
  padding: 0;
  font-size: 13px;
  margin: 0 5px 0 0;
  cursor: default;
}

.navbarLeft ul li
{
  display: flex;
  align-items: center;
  align-content: center;
}

.navbarLeft ul li
{
  margin: 0 !important;
  list-style: none;
  transition: all ease 0.5s;
  padding:10px 5px 10px 22px !important;
  font-weight: 600;
}

.navbarLeft a
{
  text-decoration: none;
  color: #fff;
}

.navbarLeft ul ul li a
{
  color: rgba(255, 255,255, 0.75);
}

.navbarLeft ul ul li
{
  padding-left: 7px !important;
  margin-left: 30px !important;
  transition: all ease 0.5s;
  font-weight: 400;
}

.navbarLeft ul ul li.active
{
  background: rgba(0, 114, 229, 0.15);
  border-radius: 5px;
}

.navbarLeft ul ul li.active a
{
  color: #0072E5 !important
}

.navbarLeft ul ul li:hover
{
  background: rgba(255,255,255, 0.15) !important;
  border-radius: 5px;
}

.navbarLeft ul ul
{
  border-left: rgba(0, 114, 229, 0.85) solid 1px;
  margin-left: 30px;
}

.editBtn
{
  background: #ff002a !important;
  color: #fff !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.detailBtn
{
  font-weight: 600 !important;
  background-color: rgba(0, 114, 229, 0.85);
  color: #fff !important;
  text-decoration: none !important;
  padding: 7px 15px;
  border-radius: 15px;
  margin: 0 15px !important;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
}

.MuiInputBase-input
{
  font-size: 13px !important;
}

.loginForm .MuiOutlinedInput-notchedOutline
{
  border: 0px !important;
  border-bottom: solid 3px rgba(0, 114, 229, 1) !important;
}

.loginForm .iconInput
{
   top: 17px !important;
}

.loginForm .MuiInputBase-input
{
  font-size: 13px !important;
  height: 40px;
}

.iconInput
{
  position: absolute;
  right:10px;
  top: 8px;
}

.tabs
{
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}

.tabs button
{
  font-weight: 700 !important;
}

.boxTable
{
  width:calc(100% - 1px);
  display: flex;
  box-sizing: border-box;
}

.boxTable .MuiDataGrid-root
{
  width: 1px!important;
}

.boxUploader
{
  width: 100%;
  display: flex;
  border: dashed 2px #D2D2D2;
  align-items: center;
  justify-content: center;
}

.boxUploader .drag
{
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
  text-align: center;
  color: #051324;
}

.boxUploader .drag .alto
{
  color: #d2d2d2;
}

.boxUploader .drag .btnUp
{
  background: #E8FAFF;
  border-radius: 20px;
  font-family: 16px !important;
  padding: 10px 25px;
  display: inline-block;
  font-weight: 500;
}

.boxUploader .drag .icon
{
  color: #D2D2D2;
  width: 50px;
  height: 50px;
}

.MuiFormLabel-root {
  font-size: 13px !important;
  margin-top: -20px !important;
  font-size: 16px !important;
  color: var(--primary) !important;
}

.MuiSelect-select {
  padding-left: 13px !important;
  padding-bottom: 0px !important; 
  padding-top: 0px !important;
  height: 40px!important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  min-width: 200px !important;
}

.whiteSelect {
  border-radius: 0px !important;
}

.whiteSelect .MuiSelect-select {
  background: white !important;
}

.whiteSelect .MuiSelect-select:focus {
  background: #fff !important;
}

.transparentSelect {
  border-radius: 0px !important;
  font-weight: 500 !important;
}

.transparentSelect .MuiSelect-select {
  background: none !important;
  color: white !important;
  border-bottom: 2px solid gray !important;

}

.transparentSelect .MuiSelect-select:focus {
  background: none !important;
}

.transparentSelect .MuiSelect-select:hover {
  border-bottom: 2px solid gray !important;

}
.transparentSelect .MuiSelect-root {
  background: none !important;
  color: #707070 !important;
  border-bottom: 2px solid white !important;

}

.transparentSelect .MuiSelect-icon {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='8.75' height='5' viewBox='0 0 8.75 5'><path id='down-arrow-backup-2-svgrepo-com' d='M72.2,256l.554.492L68.375,261,64,256.492l.554-.492,3.821,3.936Z' transform='translate(-64 -256)' fill='%23e2dba3'/></svg>");
  background-repeat: no-repeat;
  background-position: center;
}

.transparentSelect .MuiSelect-icon * {
  display: none;
}

.transparentSelect .MuiSelect-root:hover {
  border-bottom: 0px !important;

  }

  .transparentSelect svg {
    color: white !important;
  }

 .MuiFormLabel-root
  {
    
    font-weight: 900 !important;
    text-transform: uppercase !important;
  }

  .goldenBtn {
    border: 1px solid var(--primary);
    padding: 8px 20px;
    border-radius: 20px;
  }

  .goldenBtn a{
    color: var(--primary);
    text-decoration: none;
    font-size: 16px;
  }

  .icon-slider {

    margin-right: 10px;
    position: relative;
  }

  .icon-slider svg {
    fill: var(--primary);
  }


  .slick-dots1 {
    position: absolute;
    bottom: 30px !important;
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30px;
  }

  .slick-testimonial {
    position: absolute;
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 30px;
  }

  .slick-testimonial li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    outline: none;
    background: rgba(255, 255, 255, 0) !important; 
    border: 1px solid rgba(201, 167, 77, 1) !important;   
    border-radius: 50%;
  }
  
  .slick-testimonial li.slick-active button {
    background:rgba(201, 167, 77, 1) !important; 
    border: 1px solid rgba(201, 167, 77, 1) !important;   

  }

  .slick-testimonial li button:before {
    content: ""!important;
    background: transparent !important;
  }


  .slick-dots2 {
    display: none;
  }
  
  .slick-testimonial li {
    margin: 0 5px;
  }

  .slick-gallery  { 
    bottom: -198px !important;
    height: 198px !important;
    max-height: 198px !important;
    overflow-x: auto; 
    white-space: nowrap; 
    scrollbar-width: thin; 
    scrollbar-color: rgba(155, 155, 155, 0.7) transparent; 
  }

  .slick-gallery li { 
    width: 188px !important;
    height: 188px !important;
  }

  .slick-gallery li img{ 
    width: 100% !important;
    height: 100% !important;
  }
  
  .slick-dots1 li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    outline: none;
    background: rgba(255, 255, 255, 0.44) !important; 
    border: 1px solid rgba(112, 112, 112, 0.44) !important;   
    border-radius: 50%;
  }
  
  .slick-dots1 li.slick-active button {
    background: rgba(255, 255, 255, 1) !important; 
    border: 1px solid rgba(112, 112, 112, 1) !important;   

  }

  .slick-dots1 li button:before {
    content: ""!important;
    background: transparent !important;
  }

  .slider-container .icon-slider {
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .slider-container:hover .icon-slider {
    opacity: 1;

  }
  .slider-container1 {
    background-color: rgb(255, 0, 0) !important;
  }

  .slider-container1 .icon-slider {
    opacity: 1;
  }

  .user-icon {
    stroke: black; 
    stroke-width: 1.5px; 
}

.image-container {
  flex: 2 2 50%; 
  display: flex;
  justify-content: center; 
  margin-bottom: 20px; 
}

.videoDiv video{
 object-fit: cover !important;
}

.imageDiv img{
  object-fit: cover !important;
 }



@media (max-width:600px) {
  .MuiDrawer-paper{
    width: 100% !important;
  }


  .MuiListItem-root{
    /* padding-right: 15px !important;
    padding-left: 15px !important; */
    padding-right: 0px !important;
    padding-left: 0px !important;
    width: 100% !important;
    flex-grow: 1 !important;
  }

  .cartItem {
    .MuiListItemButton-root {
      padding-right: 0px !important;
      padding-left: 0px !important;
    
    }
  }

  .MuiInputBase-input{
    font-size: 16px !important;
  }
}

